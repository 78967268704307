/*
 * Fantasy theme adjustments
 */
.vjs-theme-fantasy {
  --vjs-theme-fantasy--primary: white;
  --vjs-theme-fantasy--secondary: white;
}

.vjs-theme-fantasy .vjs-big-play-button {
  color: white;
  text-shadow: var(--icon-shadow);
}

.vjs-big-play-button .vjs-icon-placeholder::before {
  font-size: 100px;
  transform: translate(-50%, -50%);
  opacity: 0.8;
}

.video-js .vjs-big-play-button:focus {
  background-color: initial;
}

.video-js .vjs-touch-overlay .vjs-play-control {
  width: 100px;
  height: 100px;
}

.vjs-touch-overlay .vjs-play-control .vjs-icon-placeholder::before {
  color: white;
  background-image: url('data:image/svg+xml;utf8,<svg fill="white" fill-opacity="0.8" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-size: cover;
  background-position: center;
  height: 100px;
  width: 100px;
}

.vjs-touch-overlay .vjs-play-control.vjs-paused .vjs-icon-placeholder::before {
  background-size: auto;
  background-image: url("data:image/svg+xml,%3Csvg width='58' height='94' viewBox='0 0 74 94' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.333328 93.6667V0.333374L73.6667 47L0.333328 93.6667Z' fill='white' fill-opacity='0.8' /%3E%3C/svg%3E%0A");
}

.vjs-control-bar {
  padding-bottom: env(safe-area-inset-bottom);
}

.vjs-control-bar .vjs-play-control .vjs-icon-placeholder::before {
  border: 2px solid white;
  top: 11px;
  left: 14px;
}

/* .video-js .vjs-touch-overlay .vjs-play-control .vjs-icon-placeholder::before {
  /* border: none; 
  margin: 0;
  left: 0;
  top: 0;
} */

/*
 * Subtitles
 * Sadly we need to use !important here to override the inline styles or browser
 * defaults
 */

.video-js {
  --subtitles-color: var(--chakra-colors-strive-primary, white);
}

video::cue {
  font-size: 1.5rem;
}

video::-webkit-media-text-track-container {
  transform: translateY(-100px) !important;
}
:is(.vjs-has-started.vjs-user-active, .vjs-paused)
  video::-webkit-media-text-track-container {
  /* Nudge the subtitles up when there are on-screen elements */
  -webkit-transform: translateY(-150px) !important;
  transform: translateY(-150px) !important;
}

.vjs-text-track-cue {
  inset: auto !important;
  bottom: 45px !important;
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  font-family: Helvetica, sans-serif !important;
  height: auto !important;
  width: auto !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  padding: 0.5rem !important;
  border-radius: 8px;
}

.vjs-text-track-cue *,
video::cue {
  /* Color the subtitles */
  color: var(--subtitles-color) !important;
  background-color: transparent !important;
}

.vjs-text-track-display,
video::-webkit-media-text-track-container {
  left: 50px;
  right: 50px;
  transition: transform 0.5s ease;
  transform: translateY(-50px);
  width: auto !important;
}
:is(.vjs-has-started.vjs-user-active, .vjs-paused) .vjs-text-track-display {
  /* Nudge the subtitles up when there are on-screen elements */
  transform: translateY(-100px);
}

.vjs-subs-caps-button {
  font-size: 1rem;
}

.vjs-captions-menu-item .vjs-icon-placeholder:before {
  /* Hide the CC icon*/
  display: none;
}

/* Hide the captions settings item from the captions menu. */
.vjs-texttrack-settings {
  display: none;
}

/*
 * Fix the video player height to 100% of the parent container & add padding to the control bar for iOS
 */

:root {
  --vjs-bottom-gutter: 0px;
  --vjs-control-bar-height: 54px;
  --vjs-control-bg: var(--chakra-colors-strive-glass, #3597b080);
}

.video-js .vjs-control-bar {
  z-index: 10;
  background: var(--vjs-control-bg);
}

.vjs-progress-control {
  /* By default when the video is <320px wide the scrubber is hidden */
  /* https://videojs.com/guides/layout/#responsive-mode */
  display: flex;
  width: 100%;
}

.video-frame {
  height: 100%;
  --control-bar-height: var(--vjs-control-bar-height);
  --header-offset: 0;
  --icon-shadow: 0px 0px 4px #00000055;
}

.video-frame--fullscreen {
  --header-offset: min(env(safe-area-inset-top), 2rem);
  --vjs-bottom-gutter: calc(env(safe-area-inset-bottom, 0) / 2);
}

.video-frame:not(.video-frame--fullscreen) .video-js {
  border-radius: 10px;
  overflow: hidden;
}

.video-frame--auto {
  height: auto;
}

.video-frame .vjs-control-bar {
  height: calc(var(--control-bar-height) + var(--vjs-bottom-gutter));
  padding-bottom: var(--vjs-bottom-gutter);
  padding-inline: calc(env(safe-area-inset-bottom) / 2);
}

.video-js .btn-strive-solid {
  background-color: white;
  color: black;
}

.video-js .btn-strive-outline {
  border: 1px solid currentColor;
  background-color: transparent;
}

.vjs-modal-dialog .vjs-modal-dialog-content {
  padding-block: 25%;
}

.vjs-modal-dialog.vjs-error-display {
  pointer-events: none;
}

/*
 * video component that should be visible when the user activates the overlay
 */
.vjs-strive {
  font-family: var(--chakra-fonts-body);
  font-size: var(--chakra-fontSizes-md);
  line-height: 1.5;
  --control-bar-height: 54px;
}

.vjs-strive-overlay-active {
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.5s ease;
}
.vjs-has-started.vjs-user-active .vjs-strive-overlay-active,
.vjs-has-started.vjs-paused .vjs-strive-overlay-paused,
:not(.vjs-has-started).vjs-paused .vjs-strive-overlay-initial {
  opacity: 1;
  transform: translateY(0) translateX(0);
}

.video-frame-header {
  width: 100%;
  position: absolute;
  top: var(--header-offset);
}

/*
 * Position the video title * playback rate button above the control bar
 */
.video-frame-footer {
  position: absolute;
  bottom: calc(
    var(--control-bar-height) + var(--vjs-bottom-gutter, 0) +
      var(--footer-offset, 0)
  );
  left: 0;
  right: 0;
  pointer-events: none;
}

.video-frame-footer > * {
  position: initial;
  pointer-events: auto;
}

.vjs-strive-overlay-inactive{
  opacity: 0.75;
  transition: opacity .5s ease;
  transition-delay: 0.5s;
}
.video-control-replace   {
  position: absolute;
  bottom: calc(
    var(--control-bar-height) / 2
  );
  left: 0;
  right: 0;
  pointer-events: none;
}

.video-control-replace > * {
  position: initial;
  pointer-events: auto;
}

.vjs-has-started.vjs-playing.vjs-user-active .vjs-strive-overlay-inactive,
.vjs-has-started.vjs-paused .vjs-strive-overlay-inactive {
  opacity: 0;
  transition: none;
}

