@font-face {
  font-family: 'Maison Neue';
  src: url('/fonts/MaisonNeue-Book.woff2') format('woff2'),
    url('/fonts/MaisonNeue-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('/fonts/MaisonNeue-Bold.woff2') format('woff2'),
    url('/fonts/MaisonNeue-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TitlingGothicFB Wide Standard';
  src: url('/fonts/TitlingGothicFBWide-Standard.woff2') format('woff2'),
    url('/fonts/TitlingGothicFBWide-Standard.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RacingSansOne-Regular';
  src: url('/fonts/RacingSansOne-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.custom-scrollbar {
  scrollbar-color: var(--scrollbar-color, var(--chakra-colors-gray-600, #777))
    var(--scrollbar-track, var(--chakra-colors-gray-500, #ccc));
  scrollbar-width: thin;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color, var(--chakra-colors-gray-600, #777));
  border-radius: 2px;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(
    --scrollbar-color-hover,
    var(--scrollbar-color, var(--chakra-colors-gray-600, #777))
  );
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: var(--scrollbar-track, var(--chakra-colors-gray-500, #ccc));
  border-radius: 0px;
  box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}