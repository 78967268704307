.str-chat {
  --str-chat__message-bubble-background-color: #033652;
  --str-chat__font-family: var(--chakra-fonts-body);
  position: absolute;
  inset: 0;
  height: 100%;
}

.str-chat.messaging {
  background-color: #191e22;
}

.str-chat__input-flat {
  z-index: 0;
}
.str-chat__container {
  height: '100%';
  flex-direction: column;
}

.str-chat__input-flat .str-chat__textarea > textarea {
  background: #3d3d3d;
}

.str-chat__header-livestream-left--title,
.str-chat__channel-header-title {
  font-size: larger;
}

.str-chat__thread-container {
  position: absolute;
}

.str-chat__modal--open .str-chat__modal__inner {
  width: 85%;
}

.str-chat__edit-message-form-options {
  gap: 2rem;
}

.str-chat__list-notifications {
  display: none;
}

/* [AS-669] Add spacing between paragraphs and text blocks */
.str-chat__message-text-inner > div {
  display: flex;
  flex-direction: column;
  gap: calc(var(--chakra-lineHeights-normal, 1) * 1em);
}
.str-chat__message-text-inner > div :is(ul, ol) {
  padding-inline-start: 1rem;
}
.str-chat__message-text-inner > div blockquote {
  border-inline-start: 2px solid currentColor;
  padding-inline-start: 0.5rem;
}

.image-gallery-slide {
  position: relative; /* Fix: AS-897 */
}


/* The following re-implements https://github.com/ArenaLabs/strive_react/pull/683 with some additional tweaks */
.image-gallery-slides {
  display: grid;
  place-items: center;
}
.image-gallery-slides > * {
  grid-area: 1/1;
}

.str-chat__modal--open {
  padding-top: 80px;
  max-height: calc(100% - env(safe-area-inset-bottom) - 145px);
}

.str-chat__modal--open button.str-chat__modal__close-button {
  z-index: 1;
  margin-top: calc(env(safe-area-inset-top) + 20px);
}

.image-gallery-image {
  margin-top: 70px;
}

.image-gallery-index {
  margin-top: calc(env(safe-area-inset-top) + 80px);
  border-radius: 5px;
  background: gray;
  opacity: 80%;
}

.str-chat__message-attachment--card .str-chat__modal--open .str-chat__modal__inner.str-chat-react__modal__inner img, .str-chat__message-attachment--image .str-chat__modal--open .str-chat__modal__inner.str-chat-react__modal__inner img, .str-chat__message-attachment--gallery .str-chat__modal--open .str-chat__modal__inner.str-chat-react__modal__inner img,
.str-chat__message-attachment--image .str-chat__modal--open .str-chat__modal__inner.str-chat-react__modal__inner img {
  max-height: calc(100vh - env(safe-area-inset-bottom) - 120px);
}

.image-gallery-fullscreen-button {
  /* Need to investigate - after opening & closing this,
     it affects CSS in a way that hides the image */
  display: none;
}